<template>
  <match-media v-slot="{ mobile }">
    <v-section :title="title" class="owner-edit-documents">
      <v-input-file-dropzone class="owner-edit-documents__file-uploader" @change="addFiles">
        <template #input-text>Выберите файл</template>
        <template #dropzone-text><span v-if="mobile"></span></template>
      </v-input-file-dropzone>
      <v-interaction-history-file-list
        :permanent-files="files"
        :temporary-files="temporaryFiles"
        @delete-file="removeFile"
      >
        <template #overlay="{file}">
          <v-overlay v-if="file.hasPending || file.hasDeletePending">
            Файл будет {{ file.hasPending ? 'опубликован' : 'удалён' }} после проверки администратором.
          </v-overlay>
        </template>
      </v-interaction-history-file-list>
    </v-section>
  </match-media>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import { DOCUMENTS_CATEGORIES } from '@/constants/documentsCategories'
import { MatchMedia } from 'vue-component-media-queries'
import { uploadingService } from '@/services/http'
import VInputFileDropzone from '@/components/common/VInputFileDropzone.vue'
import VInteractionHistoryFileList from '@/components/common/InteractionHistory/VInteractionHistoryFileList.vue'
import VOverlay from '@/components/common/VOverlay.vue'
import confirmService from '@/services/confirmation'

export default {
  name: 'OwnersEditDocuments',
  components: {
    VOverlay,
    VInteractionHistoryFileList,
    VInputFileDropzone,
    MatchMedia,
    VSection
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      required: true,
      validator: currentCategory => DOCUMENTS_CATEGORIES.find(category => category.name === currentCategory)
    },
    files: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      temporaryFiles: []
    }
  },
  computed: {
    localFiles() {
      return [...this.files, ...this.temporaryFiles]
    }
  },
  watch: {
    files: {
      immediate: true,
      handler(newFiles) {
        if (newFiles.length) {
          this.temporaryFiles = []
        }
      }
    }
  },
  methods: {
    async addFiles(files) {
      const downloadFilesResolvers = []

      files.forEach(file => {
        const temporaryFile = { progress: 0, createdAt: Date.now(), name: file.name }
        this.temporaryFiles.push(temporaryFile)

        const loadingProgress = this.getUpdateLoadingProgressCb(temporaryFile)

        const downloadFileResolver = uploadingService.uploadFile(file, loadingProgress)
        downloadFilesResolvers.push(downloadFileResolver)
      })

      const downloadFiles = await Promise.all(downloadFilesResolvers)

      const newDownloadFiles = downloadFiles.map(({ id }, index) => {
        return {
          id,
          name: this.temporaryFiles[index].name,
          createdAt: this.temporaryFiles[index].createdAt
        }
      })
      this.$emit('add-files', { ids: newDownloadFiles.map(({ id }) => id), category: this.category })
    },
    getUpdateLoadingProgressCb(file) {
      const tmpFile = this.temporaryFiles.find(f => f === file)

      return progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength) {
          tmpFile.progress = progressEvent.loaded / totalLength
        }
      }
    },
    removeFile({ id }) {
      const customComponent = {
        component: VInteractionHistoryFileList,
        attrs: { hideDeleteButton: true, permanentFiles: [this.files.find(file => file.id === id)] }
      }

      confirmService
        .ask({
          title: `Вы точно хотите удалить файл?`,
          confirmText: 'Удалить',
          cancelText: 'Отменить',
          customComponent
        })
        .then(() => {
          this.$emit('removeFile', id)
        })
        .catch(() => {})
    }
  }
}
</script>
