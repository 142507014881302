<template>
  <v-section title="Объекты собственника" class="owner-edit-objects">
    <v-form-field class="owner-edit-objects__search" label="ДОБАВИТЬ ОБЪЕКТ СОБСТВЕННИКА" separate-label hide-errors>
      <template #default>
        <v-select
          v-model="localSearch"
          clear-search-on-select
          label="name"
          :options="allObjects"
          @input="addOwnerObject"
          @search="searchObjects"
        ></v-select>
      </template>
    </v-form-field>
    <ul>
      <object-realty-list-item
        v-for="objectRealty in formattedObjects"
        :key="objectRealty.id"
        v-bind="objectRealty"
        disable-links
        compact-view
        class="owner-edit-objects__list-item"
      >
        <template v-if="objectRealty.isAdded || objectRealty.isDeleted" #overlay>
          Объект будет {{ objectRealty.isAdded ? 'добавлен' : 'удалён' }} после проверки администратором.</template
        >
        <template #actions>
          <v-button-wide v-if="isNmh && objectRealty.url" :href="objectRealty.url">Открыть на сайте</v-button-wide>
          <v-button-wide @click="editOwnerObject(objectRealty.id)">Редактировать</v-button-wide>
          <v-button-wide @click="deleteOwnerObject(objectRealty.id)">Удалить</v-button-wide>
        </template>
      </object-realty-list-item>
    </ul>
  </v-section>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import ObjectRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import VFormField from '@/components/form/VFormField.vue'
import VSelect from '@/components/common/VSelect.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import { REALTY_STATUS_ACTIVE } from '@/constants/statuses/realty'
import { formatObjectRealty } from '@/utils/formatters'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'OwnersEditObjects',
  components: { VButtonWide, VSelect, VFormField, ObjectRealtyListItem, VSection },
  props: {
    pendingObjects: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ownerObjects: {
      type: Array,
      default: () => {
        return []
      }
    },
    allObjects: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      localSearch: ''
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    formattedObjects() {
      const addedObjects = this.pendingObjects.added.map(obj => {
        return { ...obj, isAdded: true }
      })
      const ownerObjectswithDeleted = this.ownerObjects.map(obj => {
        if (this.pendingObjects.deleted.map(delObject => delObject.id).includes(obj.id)) {
          return { ...obj, isDeleted: true }
        }
        return obj
      })
      return [...addedObjects, ...ownerObjectswithDeleted].map(formatObjectRealty)
    }
  },
  methods: {
    editOwnerObject(id) {
      this.$router.push({
        name: 'objects-realty-edit',
        params: { objectId: id, status: REALTY_STATUS_ACTIVE }
      })
    },
    deleteOwnerObject(id) {
      this.$emit('delete-owner-object', id)
    },
    addOwnerObject(newObject) {
      this.$emit('add-owner-object', newObject.id)
      this.localSearch = null
    },
    searchObjects(searchRow) {
      this.$emit('search-objects', searchRow)
    }
  }
}
</script>
