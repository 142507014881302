<template>
  <div class="interaction-history-form-file-list">
    <div v-for="(file, index) in files" :key="index" class="interaction-history-form-file-list__file">
      <slot name="overlay" :file="file"></slot>
      <a class="interaction-history-form-file-list__file-link" :href="file.url" target="_blank"></a>
      <v-file-icon class="interaction-history-form-file-list__file-icon" />
      <div class="interaction-history-form-file-list__file-info">
        <div class="interaction-history-form-file-list__file-name">{{ file.name }}</div>
        <template v-if="!file.hasOwnProperty('progress') || file.progress === 1">
          <div class="interaction-history-form-file-list__file-size">{{ file.size }}</div>
        </template>
        <span v-else class="interaction-history-form-file-list__loader-wrapper">
          <span class="interaction-history-form-file-list__loader-track">
            <span
              class="interaction-history-form-file-list__loader"
              :style="{ width: `${file.progress * 100}%` }"
            ></span>
          </span>
        </span>
      </div>
      <v-button-icon
        v-if="!hideDeleteButton"
        class="interaction-history-form-file-list__cancel-button"
        @click="deleteFile(file)"
        ><v-cross></v-cross
      ></v-button-icon>
    </div>
    <div v-if="error" class="interaction-history-form-file-list__error">{{ error }}</div>
  </div>
</template>

<script>
import VFileIcon from '@/components/icons/VFile.vue'
import VCross from '@/components/icons/VCross.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { mapState } from 'vuex'

export default {
  name: 'VInteractionHistoryFileList',
  components: { VButtonIcon, VCross, VFileIcon },
  props: {
    permanentFiles: {
      type: Array,
      default: () => []
    },
    hideDeleteButton: {
      type: Boolean,
      default: false
    },
    temporaryFiles: {
      type: Array,
      default: () => []
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(MODULE_SESSION, {
      accessToken: state => state.accessToken
    }),
    files() {
      return this.permanentFiles
        .map(file => ({
          name: file.fileName,
          url: `${file.url}?token=${this.accessToken}`,
          isPermanent: true,
          id: file.id,
          size: file.size,
          hasPending: file.hasPending,
          hasDeletePending: file.hasDeletePending
        }))
        .concat(this.temporaryFiles)
    }
  },
  methods: {
    deleteFile(file) {
      this.$emit('delete-file', file)
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.interaction-history-form-file-list {
  &__loader-wrapper {
    position: relative;
    height: 16px;
    padding-top: 6px;
  }

  &__loader-track {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: $--silver;
    border-radius: $--main-border-radius;
    overflow: hidden;
  }

  &__loader {
    @include themify() {
      background-color: themed('primary');
    }
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
  }

  &__file {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 16px;
    padding: 8px 16px;
    background: $--light-gray-300;
    border-radius: 3px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &:hover {
      background: $--platinum-gray;
    }
  }

  &__file-info {
    display: flex;
    flex-direction: column;
    font-size: $--font-size-small;
    line-height: 16px;
    width: 100%;
  }

  &__file-name {
    width: 100%;
    color: $--pitch-black;
  }

  &__file-icon {
    flex-shrink: 0;
  }

  &__file-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__file-size {
    width: 100%;
    color: $--gray-550;
  }
  &__cancel-button {
    z-index: 1;
    display: block;
    flex-shrink: 0;
  }
  &__error {
    margin-top: 8px;
    color: $--text-color-error;
  }
}
</style>
