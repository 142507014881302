var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_c('div',{staticClass:"owner-edit-pending-dialog-content"},[(!mobile)?_c('h2',{staticClass:"owner-edit-pending-dialog-content__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ СОБСТВЕННИКА","error-message":_vm.disableFields ? '' : _vm.backendErrors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"value":_vm.localOwnerData.name,"disabled":_vm.disableFields,"is-error":!!validationErrors.length,"type":"text"},on:{"input":function($event){return _vm.updateLocalOwnerData('name', $event)}}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН","error-message":_vm.disableFields ? '' : _vm.backendErrors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"value":_vm.localOwnerData.phone,"disabled":_vm.disableFields,"is-error":!!validationErrors.length},on:{"input":function($event){return _vm.updateLocalOwnerData('phone', $event)}},scopedSlots:_vm._u([(_vm.successAppend)?{key:"append",fn:function(){return [(_vm.successAppend === 'success')?_c('v-success',{staticClass:"owner-edit-pending-dialog-content__success-icon"}):_c('v-not-success')]},proxy:true}:null],null,true)})]}},(!_vm.disableFields)?{key:"error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [_c('v-form-user-error',{attrs:{"error-message":errorMessage,"user":_vm.phoneSearchUser}})]}}:null],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"EMAIL","error-message":_vm.disableFields ? '' : _vm.backendErrors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"value":_vm.localOwnerData.email,"disabled":_vm.disableFields,"is-error":!!validationErrors.length,"type":"email"},on:{"input":function($event){return _vm.updateLocalOwnerData('email', $event)}}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"СТАТУС","error-message":_vm.disableFields ? '' : _vm.backendErrors.status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localOwnerData.status,"label":"name","disabled":_vm.disableFields,"append-to-body":"","is-error":!!validationErrors.length,"options":_vm.statusOptions},on:{"input":function($event){return _vm.updateLocalOwnerData('status', $event)}}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","error-message":_vm.disableFields ? '' : _vm.backendErrors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localOwnerData.agent,"label":"name","disabled":_vm.disableFields,"append-to-body":"","is-error":!!validationErrors.length,"options":_vm.agentOptions},on:{"input":function($event){return _vm.updateLocalOwnerData('agent', $event)}}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИСТОЧНИК ЛИДА","error-message":_vm.disableFields ? '' : _vm.backendErrors.callSource},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localOwnerData.callSource,"label":"name","disabled":_vm.disableFields,"append-to-body":"","is-error":!!validationErrors.length,"options":_vm.callSourceOptions},on:{"input":function($event){return _vm.updateLocalOwnerData('callSource', $event)}}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ОБЪЕКТЫ НЕДВИЖИМОСТИ","separate-label":"","hide-errors":"","error-message":_vm.disableFields ? '' : _vm.backendErrors.adverts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-form-dynamic-select',{attrs:{"value":_vm.localOwnerData.adverts,"label":"name","append-to-body":"","disabled":_vm.disableFields,"is-error":!!validationErrors.length,"errors":validationErrors,"options":_vm.advertOptions,"prevent-duplicates":"","hide-controls":!_vm.editMode},on:{"input":function($event){return _vm.updateLocalOwnerData('adverts', $event)}}})]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }