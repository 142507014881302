<template>
  <v-checkbox
    v-model="localChecked"
    class="reminder-checkbox"
    :class="{
      'reminder-checkbox--checked': checked,
      'reminder-checkbox--expired': expired
    }"
    v-bind="$attrs"
    v-on="inheritListeners"
  ></v-checkbox>
</template>

<script>
import VCheckbox from '@/components/common/VCheckbox.vue'

export default {
  name: 'VReminderCheckbox',
  components: { VCheckbox },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: { type: Boolean, default: false },
    expired: { type: Boolean, default: false }
  },
  computed: {
    inheritListeners() {
      const { change: _change, ...restListeners } = this.$listeners
      return restListeners
    },

    localChecked: {
      get() {
        return this.checked
      },
      set(newValue) {
        this.$emit('change', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.reminder-checkbox {
  .v-checkbox {
    &__fake {
      background: transparent !important;
      opacity: 0.2;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1.5px solid $--pitch-black;
      &::before {
        background-image: url(../../assets/icons/i_tick-black.svg);
      }
    }

    &__title {
      padding: 0;
    }
  }

  &--expired {
    .v-checkbox__fake {
      border-color: $--red;
      opacity: 0.5;
    }
  }

  &--checked {
    .v-checkbox__fake {
      opacity: 1;
    }

    &:hover {
      .v-checkbox__fake {
        opacity: 0.8;
      }
    }
  }

  &:hover {
    .v-checkbox__fake {
      opacity: 1;
    }
  }
}
</style>
