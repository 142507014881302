<template>
  <match-media v-slot="{ mobile }">
    <v-section title="История взаимодействий" class="interaction-history">
      <v-interaction-history-form
        v-if="!mobile"
        :entity="entityParams"
        :default-agent="defaultAgent"
        class="interaction-history__form"
        @update="fetchHistory"
      ></v-interaction-history-form>
      <v-interaction-history-list
        class="interaction-history__list"
        :history="history"
        @remove="remove"
        @edit="edit"
      ></v-interaction-history-list>
      <v-dialog size="large-plus" :visible.sync="visibleHistoryEditForm">
        <v-interaction-history-form
          :entity="entityParams"
          :interaction="interaction"
          :creation-type="creationType"
          class="interaction-history__form"
          @update="update"
          @cancel="reset"
        ></v-interaction-history-form>
      </v-dialog>
      <shows-create-dialog
        :client-id="entityId"
        :visible.sync="visibleShowEditDialog"
        :show-data="interaction"
        @submitted="update"
      />
      <calls-create-dialog :visible.sync="visibleCallEditDialog" :call-data="interaction" @submitted="update" />
    </v-section>
  </match-media>
</template>

<script>
import { loadingService } from '@/services/loading'
import noticeService from '@/services/notification'
import VSection from '@/components/common/VSection.vue'
import VDialog from '@/components/common/VDialog.vue'
import { SHOW, INCOMING_CALL } from '@/constants/ownerInteractionHistory'
import { ownersService, customersService, agentsService, remindersService } from '@/services/http'
import VInteractionHistoryForm from '@/components/common/InteractionHistory/VInteractionHistoryForm.vue'
import VInteractionHistoryList from '@/components/common/InteractionHistory/VInteractionHistoryList.vue'
import ShowsCreateDialog from '@/components/Shows/CreateDialog.vue'
import CallsCreateDialog from '@/components/Calls/CreateDialog.vue'
import { cloneDeep } from '@/utils/common'
import confirmService from '@/services/confirmation'
import { MatchMedia } from 'vue-component-media-queries'

const ACTIONS_MAP = {
  owner: {
    service: ownersService,
    id: 'ownerId'
  },
  customer: {
    service: customersService,
    id: 'customerId'
  }
}

export default {
  ACTIONS_MAP,
  name: 'VInteractionHistory',
  components: {
    MatchMedia,
    CallsCreateDialog,
    ShowsCreateDialog,
    VSection,
    VDialog,
    VInteractionHistoryForm,
    VInteractionHistoryList
  },
  props: {
    entity: {
      type: String,
      required: true,
      validator: entity => ['owner', 'customer'].includes(entity)
    },
    defaultAgent: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      history: [],
      interaction: null,
      visibleHistoryEditForm: false,
      visibleCallEditDialog: false,
      visibleShowEditDialog: false,
      loading: false,
      callSourcesList: [],
      creationType: ''
    }
  },
  provide() {
    return {
      setEventAsCompleted: this.setEventAsCompleted
    }
  },
  computed: {
    entityParams() {
      return {
        name: this.entity,
        id: this.$route.params[ACTIONS_MAP[this.entity].id],
        service: ACTIONS_MAP[this.entity].service
      }
    },
    entityId() {
      return this.entityParams.id || ''
    },
    entityService() {
      return this.entityParams.service
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    visibleCallEditDialog(val) {
      if (!val) {
        this.reset()
      }
    },
    visibleHistoryEditForm(val) {
      if (!val) {
        this.reset()
      }
    },
    visibleShowEditDialog(val) {
      if (!val) {
        this.reset()
      }
    }
  },
  created() {
    this.fetchHistory()
    this.fetchCallSources()
  },
  methods: {
    openMobileHistoryForm(type) {
      this.creationType = type
      if (type === SHOW) {
        this.visibleShowEditDialog = true
      } else this.visibleHistoryEditForm = true
    },
    setEventAsCompleted(params) {
      if (!this.loading) {
        this.loading = true
        remindersService.setEventAsCompleted(params).finally(() => {
          this.loading = false
        })
      }
    },
    fetchHistory() {
      this.loading = true
      this.entityService
        .getHistory(this.entityId)
        .then(data => {
          this.history = data
        })
        .catch(() => {
          this.history = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchCallSources() {
      return ownersService.getCallSourcesMap().then(callSources => {
        this.callSourcesList = callSources
      })
    },
    getCallSource({ account: { profile } }) {
      if (this.callSourcesList.length) {
        const { callSource } = profile
        if (callSource) {
          const source = this.callSourcesList.find(
            selectorCallSource => selectorCallSource.id === (callSource.id || callSource)
          )
          if (source) return source
        }
      }
      return this.callSourcesList.find(callSource => callSource.name.toLowerCase() === 'не выбран')
    },
    formatShowData(show) {
      if (show.account) {
        const formatShow = cloneDeep(show)
        const { phone, name } = show.account
        if (phone) formatShow.clientPhone = phone
        if (name) formatShow.clientName = name
        return formatShow
      }
      return show
    },
    formatCallData(call) {
      return {
        ...call,
        callSource: this.getCallSource(call)
      }
    },
    edit(interaction) {
      const {
        type,
        data: { id }
      } = interaction
      if (type === SHOW) {
        agentsService.getShowById(id).then(result => {
          this.interaction = this.formatShowData(result)
          this.visibleShowEditDialog = true
        })
      } else if (type === INCOMING_CALL) {
        agentsService.getCallById(id).then(result => {
          this.interaction = this.formatCallData(result)
          this.visibleCallEditDialog = true
        })
      } else {
        this.interaction = interaction
        this.visibleHistoryEditForm = true
      }
    },
    reset() {
      this.interaction = null
      this.creationType = ''
      this.visibleHistoryEditForm = false
      this.visibleCallEditDialog = false
      this.visibleShowEditDialog = false
    },
    update() {
      this.reset()
      this.fetchHistory()
    },
    removeShow(id) {
      confirmService
        .ask({ title: `Вы точно хотите удалить показ?` })
        .then(() => {
          this.loading = true
          agentsService
            .deleteShow(id)
            .then(() => {
              this.fetchHistory()
              noticeService.success('Показ удален')
            })
            .catch(() => {
              noticeService.error('Не удалось удалить показ')
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    removeIncomingCall(id) {
      confirmService
        .ask({ title: `Вы точно хотите удалить лид?` })
        .then(() => {
          this.loading = true
          agentsService
            .deleteCall(id)
            .then(() => {
              this.fetchHistory()
              noticeService.success('Лид удален')
            })
            .catch(() => {
              noticeService.error('Не удалось удалить лид')
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    removeHistory(id) {
      confirmService
        .ask({ title: `Вы точно хотите удалить запись?` })
        .then(() => {
          this.loading = true
          this.entityService
            .deleteHistory(this.entityId, id)
            .then(() => {
              this.fetchHistory()
              noticeService.success('Запись удалена')
            })
            .catch(() => {
              noticeService.error('Не удалось удалить запись')
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    remove({ id, type, data }) {
      if (type === SHOW) {
        this.removeShow(data.id)
      } else if (type === INCOMING_CALL) {
        this.removeIncomingCall(data.id)
      } else this.removeHistory(id)
    }
  }
}
</script>
