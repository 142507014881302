<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none">
    <path
      fill="#9783F1"
      d="M10 0H2C.9 0 .0100002.9.0100002 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0
      2-.9 2-2V6l-6-6Zm2 16H4v-2h8v2Zm0-4H4v-2h8v2ZM9 7V1.5L14.5 7H9Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VFile'
}
</script>
