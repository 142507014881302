<template>
  <section class="v-section">
    <div v-if="title || $slots.headerAppend" class="v-section__header">
      <h3 class="v-section__title">{{ title }}</h3>
      <slot name="header-append" />
    </div>
    <div class="v-section__content">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'VSection',
  props: {
    title: { type: String, default: '' }
  }
}
</script>
