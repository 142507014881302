var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"edit-owner-dialog",attrs:{"visible":_vm.localVisible,"title":_vm.title},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"body-scroll-lock-ignore":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН","rules":_vm.rules.phone,"error-message":_vm.errors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"is-error":!!validationErrors.length},scopedSlots:_vm._u([(_vm.successAppend)?{key:"append",fn:function(){return [(_vm.successAppend === 'success')?_c('v-success',{staticClass:"edit-owner-dialog__success-icon"}):_c('v-not-success')]},proxy:true}:null],null,true),model:{value:(_vm.owner.phone),callback:function ($$v) {_vm.$set(_vm.owner, "phone", $$v)},expression:"owner.phone"}})]}},{key:"error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [_c('v-form-user-error',{attrs:{"error-message":errorMessage,"user":_vm.phoneSearchUser}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ СОБСТВЕННИКА","rules":_vm.rules.name,"error-message":_vm.errors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"text"},model:{value:(_vm.owner.name),callback:function ($$v) {_vm.$set(_vm.owner, "name", $$v)},expression:"owner.name"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"EMAIL","rules":_vm.rules.email,"error-message":_vm.errors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"email"},model:{value:(_vm.owner.email),callback:function ($$v) {_vm.$set(_vm.owner, "email", $$v)},expression:"owner.email"}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","rules":_vm.rules.agent,"error-message":_vm.errors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.agentOptions,"loading":_vm.agentOptionsLoading},model:{value:(_vm.owner.agent),callback:function ($$v) {_vm.$set(_vm.owner, "agent", $$v)},expression:"owner.agent"}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"СТАТУС","rules":_vm.rules.status,"error-message":_vm.errors.status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.statusOptions},model:{value:(_vm.owner.status),callback:function ($$v) {_vm.$set(_vm.owner, "status", $$v)},expression:"owner.status"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИСТОЧНИК ЛИДА","rules":_vm.rules.callSource,"error-message":_vm.errors.callSource},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.callSourceOptions},model:{value:(_vm.owner.callSource),callback:function ($$v) {_vm.$set(_vm.owner, "callSource", $$v)},expression:"owner.callSource"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ОБЪЕКТ","rules":_vm.rules.object,"separate-label":"","hide-errors":"","error-message":_vm.errors.adverts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-form-dynamic-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"errors":validationErrors,"options":_vm.advertOptions,"prevent-duplicates":"","loading":_vm.advertOptionsLoading},on:{"search":_vm.onAdvertSearch},model:{value:(_vm.owner.adverts),callback:function ($$v) {_vm.$set(_vm.owner, "adverts", $$v)},expression:"owner.adverts"}})]}}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-button',{staticClass:"edit-owner-dialog__cancel-button",attrs:{"type":"button"},on:{"click":function($event){_vm.localVisible = false}}},[_vm._v("Отменить")]),_c('v-button',{attrs:{"disabled":!_vm.isCreation && _vm.isFieldsNotChanged,"primary":"","type":"submit"}},[_vm._v(_vm._s(_vm.isCreation ? 'Добавить собственника' : 'Обновить контакт'))])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }