<template>
  <div class="interaction-history-list">
    <v-timeline v-if="hasFutureInteractions" title="В будущем">
      <v-timeline-item v-for="(interaction, interactionIndex) in futureInteractions" :key="interactionIndex"
        ><v-interaction-history-list-item
          :interaction="interaction"
          class="interaction-history-list__item"
          @edit="edit(interaction)"
          @remove="remove(interaction)"
        ></v-interaction-history-list-item
      ></v-timeline-item>
    </v-timeline>
    <v-timeline v-if="hasOldInteractions" title="В прошлом">
      <v-timeline-item
        v-for="(interaction, interactionIndex) in oldInteractions"
        :key="interactionIndex"
        :diff="interaction.diff"
        ><v-interaction-history-list-item
          :interaction="interaction"
          class="interaction-history-list__item"
          @edit="edit(interaction)"
          @remove="remove(interaction)"
        ></v-interaction-history-list-item
      ></v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import VTimeline from '@/components/common/VTimeline.vue'
import VTimelineItem from '@/components/common/VTimelineItem.vue'
import { parse, isBefore, startOfDay, differenceInDays, addDays, intervalToDuration, formatDuration } from 'date-fns'
import { ru } from 'date-fns/locale'

import { cloneDeep } from '@/utils/common'
import VInteractionHistoryListItem from '@/components/common/InteractionHistory/VInteractionHistoryListItem.vue'

export default {
  name: 'VInteractionHistoryList',
  components: { VTimeline, VTimelineItem, VInteractionHistoryListItem },
  props: {
    history: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      futureInteractions: [],
      oldInteractions: []
    }
  },
  computed: {
    hasFutureInteractions() {
      return Boolean(this.futureInteractions.length)
    },
    hasOldInteractions() {
      return Boolean(this.oldInteractions.length)
    }
  },
  watch: {
    history(val) {
      this.showHistory(val)
    }
  },
  methods: {
    showHistory(history) {
      const formattedInteractions = this.formatInteractions(history)
      const { futureInteractions, oldInteractions } = this.filterInteractions(formattedInteractions)
      oldInteractions.sort((a, b) => b.datetimeAt - a.datetimeAt)
      futureInteractions.sort((a, b) => b.datetimeAt - a.datetimeAt)
      this.futureInteractions = futureInteractions
      this.oldInteractions = this.setDiff(oldInteractions)
    },
    formatInteractions(interactions) {
      return interactions.map(interaction => {
        let localInteraction = cloneDeep(interaction)

        if (localInteraction.objectDraft) {
          const {
            type,
            text,
            filesAdd,
            filesDelete,
            reminderDatetime,
            advert,
            showDate,
            showTime,
            callDate,
            showStatus,
            isPrimary
          } = localInteraction.objectDraft
          let filesListWithoutDeleted = []
          let filesListWithAdded = []

          if (localInteraction?.files?.length && filesDelete) {
            filesListWithoutDeleted = localInteraction?.files?.filter(
              file => !filesDelete.map(delFile => delFile.id).includes(file.id)
            )
          }

          filesListWithAdded = filesAdd ? [...filesListWithoutDeleted, ...filesAdd] : filesListWithoutDeleted
          localInteraction = {
            ...localInteraction,
            type: type || localInteraction.type,
            data: { text, advert, showStatus, isPrimary },
            files: filesListWithAdded,
            reminderDatetime: reminderDatetime || localInteraction.reminderDatetime,
            hasPending: true,
            historyDate: showDate || callDate || localInteraction.historyDate,
            historyTime: showTime || localInteraction.historyTime
          }
        }

        const date = localInteraction.reminderDatetime
          ? `${localInteraction.reminderDatetime.slice(0, 10)} ${localInteraction.historyTime}`
          : `${localInteraction.historyDate} ${localInteraction.historyTime}`
        const datetimeAt = parse(date, 'dd.MM.yyyy HH:mm', new Date())
        return {
          ...localInteraction,
          datetimeAt
        }
      })
    },
    filterInteractions(interactions) {
      const futureInteractions = []
      const oldInteractions = []
      const currentDay = startOfDay(new Date())
      interactions.forEach(interaction => {
        const isOld = !interaction.reminderDatetime || isBefore(interaction.datetimeAt, addDays(currentDay, 1))
        if (isOld) {
          oldInteractions.push(interaction)
        } else {
          futureInteractions.push(interaction)
        }
      })
      return { futureInteractions, oldInteractions }
    },
    setDiff(interactions) {
      const localInteractions = cloneDeep(interactions)
      localInteractions.forEach((interaction, i) => {
        if (i === 0) return
        const date1 = localInteractions[i - 1].reminderDatetime
          ? parse(localInteractions[i - 1].reminderDatetime, 'dd.MM.yyyy HH:mm', new Date())
          : new Date(localInteractions[i - 1].datetimeAt)
        const date2 = interaction.reminderDatetime
          ? parse(interaction.reminderDatetime, 'dd.MM.yyyy HH:mm', new Date())
          : new Date(interaction.datetimeAt)
        if (differenceInDays(date1, date2) > 1) {
          const duration = intervalToDuration({ start: date2, end: date1 })
          if (duration.months) duration.days = 0
          if (duration.years) duration.months = 0
          localInteractions[i].diff = formatDuration(duration, {
            format: ['years', 'months', 'days'],
            locale: ru
          })
        }
      })
      return localInteractions
    },
    edit(interaction) {
      this.$emit('edit', interaction)
    },
    remove(interaction) {
      this.$emit('remove', interaction)
    }
  }
}
</script>
