<template>
  <match-media v-slot="{ mobile }">
    <v-section title="Информация" class="owner-edit-about">
      <v-info-field class="owner-edit-about__info-field" title="Дата обновления">
        {{ formatDate(updatedAt) }}
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Дата создания">
        {{ formatDate(dateJoined) }}
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Статус" editable @edit="edit">
        <v-tag
          v-if="status"
          :class="{ 'crossed-out-block offset-bottom': isOwnerFieldChanged('status') }"
          :custom-color="status.color"
        >
          {{ status.shortname || status.name }}</v-tag
        >
        <span v-else>-</span>
        <v-tag v-if="isOwnerFieldChanged('status')" :custom-color="textChangedField('status').color">
          {{ textChangedField('status').shortname || textChangedField('status').name }}</v-tag
        >
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Имя" editable @edit="edit">
        <span :class="{ 'crossed-out': isOwnerFieldChanged('name') }">{{ name }}</span>
        <span v-if="isOwnerFieldChanged('name')">{{ textChangedField('name') }}</span>
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Телефон" editable @edit="edit">
        <span v-if="!mobile" :class="{ 'crossed-out': isOwnerFieldChanged('phone') }">{{ formatPhone(phone) }}</span>
        <v-popover-actions v-else class="owner-item__popover">
          <span :class="{ 'crossed-out': isOwnerFieldChanged('phone') }">{{ formatPhone(phone) }}</span>
          <template #actions>
            <v-button-wide :href="`tel:${phone}`">Позвонить</v-button-wide>
            <v-button-wide :href="`https://wa.me/${phone.replace(/^8/, '+7')}`">Написать в WhatsApp</v-button-wide>
          </template>
        </v-popover-actions>
        <span v-if="isOwnerFieldChanged('phone')">{{ formatPhone(textChangedField('phone')) }}</span>
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Почта" editable @edit="edit">
        <span :class="{ 'crossed-out': isOwnerFieldChanged('email') }">{{ email }}</span>
        <span v-if="isOwnerFieldChanged('email')">{{ textChangedField('email') }}</span>
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Агент" :editable="isRoleAdmin" @edit="edit">
        <v-agent v-if="ownerProfile.agent" :photo="ownerProfile.agent.photo">
          {{ ownerProfile.agent.name }}
        </v-agent>
        <span v-else>-</span>
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Агент первичный">
        <v-agent v-if="ownerProfile.previousAgent" :photo="ownerProfile.previousAgent.photo">
          {{ ownerProfile.previousAgent.name }}
        </v-agent>
        <span v-else>-</span>
      </v-info-field>
      <v-info-field class="owner-edit-about__info-field" title="Источник лида" editable @edit="edit">
        <span :class="{ 'crossed-out': isOwnerFieldChanged('callSource') }">{{ callSource.name || '-' }}</span>
        <span v-if="isOwnerFieldChanged('callSource')">{{ textChangedField('callSource') }}</span>
      </v-info-field>
    </v-section>
  </match-media>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import VInfoField from '@/components/common/VInfoField.vue'
import { formatDate, formatPhone } from '@/utils/formatters'
import VAgent from '@/components/common/VAgent.vue'
import VTag from '@/components/common/VTag.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'

export default {
  name: 'OwnersEditAbout',
  components: { VButtonWide, MatchMedia, VPopoverActions, VTag, VAgent, VInfoField, VSection },
  props: {
    name: {
      type: String,
      default: '-'
    },
    phone: {
      type: String,
      default: '-'
    },
    email: {
      type: String,
      default: '-'
    },
    dateJoined: {
      type: String,
      default: '-'
    },
    lastLogin: {
      type: String,
      default: '-'
    },
    updatedAt: {
      type: String,
      default: '-'
    },
    status: {
      type: [String, Object],
      default: '-'
    },
    callSource: {
      type: [String, Object],
      default: '-'
    },
    ownerProfile: {
      type: Object,
      default: () => {
        return {}
      }
    },
    changes: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    })
  },
  methods: {
    isOwnerFieldChanged(field) {
      if (!this.changes) return false
      return !!this.changes[field] || !!this.changes.ownerProfile?.[field]?.newValue.name
    },
    textChangedField(field) {
      if (field === 'status') return this.changes.ownerProfile?.[field]?.newValue
      return this.changes[field]?.newValue || this.changes.ownerProfile?.[field]?.newValue.name
    },
    formatPhone(phone) {
      return phone ? formatPhone(phone) : '-'
    },
    formatDate(date) {
      return date ? formatDate(date, ' ', true) : '-'
    },
    edit() {
      this.$emit('edit')
    }
  }
}
</script>

<style scoped lang="scss">
.crossed-out {
  margin-right: 5px;
  text-decoration: line-through;
}

.crossed-out-block {
  position: relative;
  margin-bottom: 5px;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    position: absolute;
    top: 50%;
    left: 0;
  }
}
</style>
