<template>
  <div :class="['v-timeline-item', { 'v-timeline-item--diff': diff }]">
    <div v-if="diff" class="v-timeline-item__diff">
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-text">{{ diff }}</div>
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-delimeter"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VTimelineItem',
  props: {
    diff: {
      type: String,
      default: ''
    }
  }
}
</script>
