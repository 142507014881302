<template>
  <match-media v-slot="{ mobile }">
    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
      tag="div"
      :class="['interaction-history-form', { 'interaction-history-form--edit': editMode || creationType }]"
    >
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <template #header>
          <v-form-row class="interaction-history-form__title">
            <h2>{{ formTitle }}</h2>
          </v-form-row>
        </template>
        <v-tabs v-model="historyData.type" class="interaction-history-form__tabs" primary>
          <v-tab v-for="(formType, index) of tabsMap" :key="index" :name="formType.value">
            {{ formType.label }}
          </v-tab>
        </v-tabs>
        <template v-if="mobile">
          <v-form-row class="interaction-history-form__mobile-date">
            <v-form-field label="ДАТА">
              <v-input-date
                v-model="historyData.reminderDate"
                :clearable="false"
                format="DD.MM.YYYY"
                value-type="format"
              >
              </v-input-date>
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="showTimeEdit" class="interaction-history-form__mobile-time">
            <v-form-field label="ВРЕМЯ" separate-label>
              <div class="interaction-history-form__time">
                <v-select
                  v-model="historyData.reminderTimeHours"
                  class="interaction-history-form__time-select"
                  label="name"
                  append-to-body
                  :options="$options.HOURS"
                  :clearable="false"
                />
                <span>:</span>
                <v-select
                  v-model="historyData.reminderTimeMinutes"
                  class="interaction-history-form__time-select"
                  label="name"
                  append-to-body
                  :options="$options.MINUTES"
                  :clearable="false"
                />
              </div>
            </v-form-field>
          </v-form-row>
        </template>
        <v-form-row class="interaction-history-form__comment">
          <v-form-field :label="mobile ? 'КОММЕНТАРИЙ' : ''" rules="required">
            <template #default="{validationErrors}">
              <v-textarea
                v-model="historyData.text"
                :is-error="!!validationErrors.length"
                :rows="mobile ? 2 : 1"
                class="interaction-history-form__comment-field"
                :placeholder="!mobile && 'Комментарий'"
              />
            </template>
          </v-form-field>
        </v-form-row>
        <template v-if="historyTypeIsShow">
          <v-form-row v-if="isRoleAdmin">
            <v-form-field rules="required">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="historyData.agent"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="agentOptions"
                  :loading="agentOptionsLoading"
                  placeholder="Выберите агента"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field rules="required" separate-label>
              <template #default="{ validationErrors }">
                <v-form-dynamic-select
                  v-model="historyData.adverts"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="advertOptions"
                  prevent-duplicates
                  placeholder="Выберите объект"
                  :loading="advertOptionsLoading"
                  @search="onAdvertSearch"
                >
                </v-form-dynamic-select>
              </template>
            </v-form-field>
          </v-form-row>
          <v-input-file-dropzone
            class="interaction-history-form__dropzone"
            :accept="$options.OWNER_ACCEPTED_FILE_TYPES"
            @change="handleFileChange"
          >
            <template #input-text> Выберите файл</template>
          </v-input-file-dropzone>
        </template>
        <v-interaction-history-file-list
          v-show="fileListVisible"
          class="interaction-history-form__files"
          :permanent-files="permanentFiles"
          :temporary-files="temporaryFiles.filter(file => file.isShowFiles === historyTypeIsShow)"
          :error="error.file"
          @delete-file="deleteFile"
        />
        <template #footer>
          <div class="interaction-history-form__actions">
            <div class="interaction-history-form__actions-start">
              <v-input-date
                v-if="!mobile"
                v-model="historyData.reminderDate"
                :clearable="false"
                format="DD.MM.YYYY"
                value-type="format"
              >
                <template #icon-calendar><span></span></template>
                <template #input>
                  <v-button compressed>{{ viewDate }}</v-button>
                </template>
              </v-input-date>
              <div v-if="!mobile && showTimeEdit" class="interaction-history-form__time">
                <v-select
                  v-model="historyData.reminderTimeHours"
                  class="interaction-history-form__time-select"
                  label="name"
                  append-to-body
                  :options="$options.HOURS"
                  :clearable="false"
                >
                  <template #open-indicator><span></span></template
                ></v-select>
                <span>:</span>
                <v-select
                  v-model="historyData.reminderTimeMinutes"
                  class="interaction-history-form__time-select"
                  label="name"
                  append-to-body
                  :options="$options.MINUTES"
                  :clearable="false"
                >
                  <template #open-indicator><span></span></template
                ></v-select>
              </div>
              <v-button-file
                v-show="isFormType($options.HISTORY_TYPES.COMMENT)"
                compressed
                multiple
                :accept="$options.OWNER_ACCEPTED_FILE_TYPES"
                @change="handleFileChange"
                >Файл</v-button-file
              >
            </div>
            <div class="interaction-history-form__actions-end">
              <v-button v-show="editMode" @click.prevent="cancel">Отменить</v-button>
              <v-button :disabled="addFilesDisabled" primary type="submit">{{ textSubmitButton }}</v-button>
            </div>
          </div>
        </template>
      </v-form>
    </validation-observer>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import { loadingService } from '@/services/loading'
import { advertsService, agentsService, uploadingService } from '@/services/http'
import { OWNER_ACCEPTED_FILE_TYPES } from '@/constants/fileTypes'
import { INTERACTION_HISTORY_TYPES_GROUP } from '@/constants/ownerInteractionHistory'
import { HISTORY_TYPES, HISTORY_TYPES_MAP } from '@/constants/historyEntryTypes'
import { formatAgentObject, formatDate } from '@/utils/formatters'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import VButton from '@/components/common/VButton.vue'
import VButtonFile from '@/components/common/VButtonFile.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VInteractionHistoryFileList from '@/components/common/InteractionHistory/VInteractionHistoryFileList.vue'
import { HOURS, MINUTES } from '@/constants/timeOptions'
import VSelect from '@/components/common/VSelect.vue'
import VFormDynamicSelect from '@/components/form/VFormDynamicSelect.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VInputFileDropzone from '@/components/common/VInputFileDropzone.vue'

export default {
  HOURS,
  MINUTES,
  HISTORY_TYPES,
  OWNER_ACCEPTED_FILE_TYPES,

  name: 'VInteractionHistoryForm',
  components: {
    VInputFileDropzone,
    VFormDynamicSelect,
    VSelect,
    VInputDate,
    MatchMedia,
    VForm,
    VButton,
    VButtonFile,
    VFormRow,
    VFormField,
    VTextarea,
    VTabs,
    VTab,
    VInteractionHistoryFileList
  },
  props: {
    creationType: {
      type: String,
      default: ''
    },
    interaction: {
      type: Object,
      default: () => null
    },
    entity: {
      type: Object,
      required: true
    },
    defaultAgent: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      agentOptionsLoading: false,
      advertOptionsLoading: false,
      agentOptions: [],
      advertOptions: [],
      addFilesDisabled: false,
      historyData: this.createDefaultHistoryData(),
      temporaryFiles: [],
      permanentFiles: [],
      loading: false,
      error: {
        file: ''
      }
    }
  },
  computed: {
    tabsMap() {
      return HISTORY_TYPES_MAP.filter(type => {
        return this.entity.name === 'owner' || this.editMode
          ? ![HISTORY_TYPES.FILE, HISTORY_TYPES.SHOW].includes(type.value)
          : type.value !== HISTORY_TYPES.FILE
      })
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    showTimeEdit() {
      return [HISTORY_TYPES.OUTGOING_CALL, HISTORY_TYPES.SHOW].includes(this.historyData.type)
    },
    viewDate() {
      const { reminderDate } = this.historyData
      if (!reminderDate || reminderDate === formatDate(new Date(), '.')) return 'Сегодня'
      return reminderDate
    },
    historyTypeIsShow() {
      return this.isFormType(this.$options.HISTORY_TYPES.SHOW)
    },
    editMode() {
      return !!this.interaction
    },
    formTitle() {
      if (this.editMode) {
        return 'Редактирование записи'
      }
      return this.creationType
        ? INTERACTION_HISTORY_TYPES_GROUP[this.historyData.type].fullText ||
            INTERACTION_HISTORY_TYPES_GROUP[this.historyData.type].text
        : 'Новая запись'
    },
    textSubmitButton() {
      if (this.editMode) {
        return 'Обновить'
      }
      return `Добавить ${(this.creationType &&
        INTERACTION_HISTORY_TYPES_GROUP[this.historyData.type]?.text?.toLowerCase()) ||
        ''}`
    },
    fileListVisible() {
      return (
        (this.isFormType(this.$options.HISTORY_TYPES.COMMENT) || this.historyTypeIsShow) &&
        (this.permanentFiles.length || this.temporaryFiles.length)
      )
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    interaction: {
      immediate: true,
      handler(val) {
        if (val) {
          const { data, type, reminderDatetime, files } = val
          this.historyData = {
            type: type === this.$options.HISTORY_TYPES.FILE ? this.$options.HISTORY_TYPES.COMMENT : type,
            reminderDate: reminderDatetime.slice(0, 10),
            reminderTimeHours: reminderDatetime?.split(' ')[1]?.split(':')[0] || '00',
            reminderTimeMinutes: reminderDatetime?.split(' ')[1]?.split(':')[1] || '00',
            text: data.text,
            files: [],
            deletedFiles: []
          }
          this.permanentFiles = files ?? []
        }
      }
    }
  },
  beforeDestroy() {
    this.revokeObjectURL()
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectAdverts()
  },
  methods: {
    onAdvertSearch(query, loading) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
          this.historyData.agent = this.agentOptions.find(agent => agent.id === this.defaultAgent.id)
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectAdverts(query) {
      this.advertOptionsLoading = true
      return advertsService
        .select(query)
        .then(advertsList => {
          this.advertOptions = advertsList.map(advert => {
            const { name, roundedPrice } = advert
            return {
              ...advert,
              name: `${name} - ${roundedPrice}`
            }
          })
        })
        .finally(() => {
          this.advertOptionsLoading = false
        })
    },
    createDefaultHistoryData() {
      return {
        type: this.creationType || HISTORY_TYPES.OUTGOING_CALL,
        agent: this.agentOptions?.find(agent => agent.id === this.defaultAgent.id) || undefined,
        adverts: undefined,
        text: '',
        files: [],
        deletedFiles: [],
        photos: [],
        reminderDate: formatDate(new Date(), '.'),
        reminderTimeHours: '00',
        reminderTimeMinutes: '00'
      }
    },
    deleteFile(deletedFile) {
      if (deletedFile.isPermanent) {
        this.permanentFiles = this.permanentFiles.filter(file => file.id !== deletedFile.id)
        this.historyData.deletedFiles.push(deletedFile.id)
      } else {
        this.temporaryFiles = this.temporaryFiles.filter(file => file.id !== deletedFile.id)
        this.historyData[this.historyTypeIsShow ? 'photos' : 'files'] = this.historyData[
          this.historyTypeIsShow ? 'photos' : 'files'
        ].filter(file => file.backgroundFileId !== deletedFile.id)
      }
    },
    isFormType(type) {
      return this.historyData.type === type
    },
    getLoadingProgress(file) {
      const temporaryFile = this.temporaryFiles.find(f => f === file)

      return progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader('x-decompressed-content-length')

        // todo: придумать как сделать получше
        if (totalLength) {
          temporaryFile.progress = progressEvent.loaded / totalLength - 0.01
        }
      }
    },
    handleFileChange(files) {
      const downloadFilesResolvers = []
      this.addFilesDisabled = true
      files.forEach(file => {
        const temporaryFile = {
          name: file.name,
          url: URL.createObjectURL(file),
          progress: 0,
          size: undefined,
          isShowFiles: this.historyTypeIsShow
        }
        this.temporaryFiles.push(temporaryFile)

        const downloadFileResolver = uploadingService[this.historyTypeIsShow ? 'uploadImage' : 'uploadFile'](
          file,
          this.getLoadingProgress(temporaryFile)
        )
          .then(({ id: backgroundFileId, size }) => {
            temporaryFile.id = backgroundFileId
            temporaryFile.size = size
            this.historyData[this.historyTypeIsShow ? 'photos' : 'files'].push({
              backgroundFileId,
              name: file.name
            })
          })
          .then(() => {
            temporaryFile.progress = 1
          })
        downloadFilesResolvers.push(downloadFileResolver)
      })
      Promise.all(downloadFilesResolvers).finally(() => {
        this.addFilesDisabled = false
      })
    },
    getActualInteractionType() {
      if (this.interaction?.type === HISTORY_TYPES.FILE && this.historyData.type !== HISTORY_TYPES.COMMENT)
        return this.historyData.type
      return (this.historyData.files.length || this.interaction?.type === HISTORY_TYPES.FILE) && !this.historyTypeIsShow
        ? HISTORY_TYPES.FILE
        : this.historyData.type
    },
    getDataForSubmitMethod(type) {
      const {
        agent,
        files,
        deletedFiles,
        adverts,
        photos,
        reminderDate,
        reminderTimeHours,
        reminderTimeMinutes,
        text
      } = this.historyData

      if (type === HISTORY_TYPES.SHOW) {
        return {
          agent,
          showDate: reminderDate,
          showTime: `${reminderTimeHours}:${reminderTimeMinutes}`,
          adverts,
          description: text,
          account: { id: this.entity.id },
          photos
        }
      }

      const payload = {
        type,
        text,
        reminderDatetime: `${reminderDate} ${reminderTimeHours}:${reminderTimeMinutes}`,
        files,
        deletedFiles
      }
      if (this.editMode && type === HISTORY_TYPES.OUTGOING_CALL) {
        const changedFields = {
          comment: text !== this.interaction.data.text,
          date: reminderDate !== this.interaction.reminderDatetime.split(' ')[0],
          time: [reminderTimeHours, reminderTimeMinutes].join(':') !== this.interaction.reminderDatetime.split(' ')[1]
        }
        return { ...payload, changedFields }
      }
      return payload
    },
    getMethodToOnSubmit() {
      let method
      const methodData = this.getDataForSubmitMethod(this.getActualInteractionType())
      if (this.historyTypeIsShow) {
        method = agentsService.createShow(methodData, true)
      } else {
        const { id: clientId, service } = this.entity
        method = this.editMode
          ? service.updateHistory(clientId, this.interaction.id, methodData)
          : service.createHistory(clientId, methodData)
      }
      return method
    },
    onSubmit() {
      this.loading = true
      this.error.file = ''
      const method = this.getMethodToOnSubmit()
      method
        .then(() => {
          this.$emit('update')
          this.reset()
        })
        .catch(({ details }) => {
          if (details) {
            this.error.file = details?.file?.join(' ') ?? ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      this.$emit('cancel')
      this.reset()
    },
    reset() {
      this.historyData = this.createDefaultHistoryData()
      this.temporaryFiles = []

      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    revokeObjectURL() {
      this.temporaryFiles.forEach(({ url }) => {
        URL.revokeObjectURL(url)
      })
      this.temporaryFiles = []
    }
  }
}
</script>
