<template>
  <div class="info-field">
    <div class="info-field__title">{{ title }}</div>
    <div class="info-field__content">
      <div class="info-field__information">
        <slot></slot>
      </div>
      <v-button v-if="editable" class="info-field__edit-button" is-text @click="$emit('edit')">редактировать</v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'VInfoField',
  components: { VButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
