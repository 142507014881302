<template>
  <match-media v-slot="{ mobile }">
    <v-dialog
      :title="mobile ? 'Запрос на изменения' : ''"
      :visible.sync="localVisible"
      size="extra-large"
      class="owner-edit-pending-dialog"
    >
      <v-form body-scroll-lock-ignore class="owner-edit-pending-dialog__form" @submit.prevent="onSubmit">
        <template v-if="mobile" #header>
          <v-tabs v-model="activeTab" class="owner-edit-pending-dialog__form-tabs" primary>
            <v-tab name="left">
              {{ $options.TABS_LABELS_MAP[editMode ? 'SEND' : 'BEFORE_SENDING'] }}
            </v-tab>
            <v-tab name="right">
              {{ $options.TABS_LABELS_MAP[editMode ? 'EDIT' : 'SEND'] }}
            </v-tab>
          </v-tabs>
        </template>
        <div class="owner-edit-pending-dialog__form-body">
          <owner-edit-pending-dialog-form-content-wrapper
            v-for="({ props, params }, index) in formColumns"
            v-show="params.show"
            :key="index"
            v-bind="props"
            class="owner-edit-pending-dialog__form-content-wrapper"
            :class="{ 'owner-edit-pending-dialog__form-content-wrapper--mobile': mobile }"
            :advert-options="advertOptions"
            :agent-options="agentOptions"
            :status-options="statusOptions"
            :call-source-options="callSourceOptions"
            @change="setLocalOwnerData"
          />
        </div>

        <template #footer>
          <v-button class="owner-edit-pending-dialog__form-button" @click.prevent="editMode = !editMode">{{
            editMode ? 'Отменить' : 'Редактировать запрос'
          }}</v-button>
          <v-button
            v-if="editMode"
            :disabled="isFieldsNotChanged"
            class="owner-edit-pending-dialog__form-button"
            primary
            type="submit"
            >{{ isRoleAdmin ? 'Применить изменения' : 'Отправить обновленный запрос' }}
          </v-button>
        </template>
      </v-form>
    </v-dialog>
  </match-media>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import { advertsService, agentsService, ownersService } from '@/services/http'
import { formatAdvertName, formatAgentObject } from '@/utils/formatters'
import { cloneDeep, getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import OwnerEditPendingDialogFormContentWrapper from '@/components/Owner/EditPendingDialogFormContentWrapper.vue'
import VButton from '@/components/common/VButton.vue'
import VForm from '@/components/form/VForm.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import { MatchMedia } from 'vue-component-media-queries'

const TABS_LABELS_MAP = {
  BEFORE_SENDING: 'До отправки',
  SEND: 'Отправлено',
  EDIT: 'Редактирование'
}

const EMPTY_OWNER = {
  name: '',
  phone: '',
  email: '',
  agent: null,
  status: null,
  callSource: null,
  adverts: []
}

export default {
  TABS_LABELS_MAP,
  name: 'OwnerEditPendingDialog',
  components: {
    VTab,
    VTabs,
    VForm,
    VButton,
    OwnerEditPendingDialogFormContentWrapper,
    VDialog,
    MatchMedia
  },
  props: {
    visible: { type: Boolean, required: true },
    ownerData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    draftData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      activeTab: 'right',
      localOwnerData: {},
      loading: false,
      errors: {},
      advertOptions: [],
      agentOptions: [],
      statusOptions: [],
      callSourceOptions: [],
      editMode: false
    }
  },
  inject: ['mediaQueries'],
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.ownerData, this.localOwnerData, { isAdmin: this.isRoleAdmin })
    },
    formColumns() {
      return [
        {
          props: {
            title: this.editMode ? TABS_LABELS_MAP.SEND : TABS_LABELS_MAP.BEFORE_SENDING,
            ownerData: this.editMode ? this.formatOwnerData(this.draftData) : this.formatOwnerData(this.ownerData),
            disableFields: true,
            editMode: false,
            backendErrors: null
          },
          params: {
            show: !this.mediaQueries.mobile || this.isFormType('left')
          }
        },
        {
          props: {
            title: this.editMode ? TABS_LABELS_MAP.EDIT : TABS_LABELS_MAP.SEND,
            ownerData: this.editMode ? this.localOwnerData : this.formatOwnerData(this.draftData),
            disableFields: !this.editMode,
            editMode: this.editMode,
            backendErrors: this.errors
          },
          params: {
            show: !this.mediaQueries.mobile || this.isFormType('right')
          }
        }
      ]
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.editMode = false
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    editMode: {
      handler() {
        this.localOwnerData = this.formatOwnerData(this.draftData)
        this.errors = {}
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectAdverts()
    this.selectStatuses()
    this.selectCallSources()
  },
  methods: {
    formatOwnerData(data) {
      return {
        ...EMPTY_OWNER,
        ...cloneDeep(data),
        adverts: data?.adverts?.length
          ? data.adverts.map(advert => {
              return { ...advert, name: formatAdvertName(advert, !advert) }
            })
          : []
      }
    },
    isFormType(type) {
      return this.activeTab === type
    },
    setLocalOwnerData(newData) {
      this.localOwnerData = newData
    },
    selectAgents() {
      return agentsService.select().then(agentList => {
        this.agentOptions = agentList.map(agent => formatAgentObject(agent))
      })
    },
    selectAdverts() {
      return advertsService.select().then(advertsList => {
        this.advertOptions = advertsList.map(advert => {
          const { name, roundedPrice } = advert
          return {
            ...advert,
            name: `${name} - ${roundedPrice}`
          }
        })
      })
    },
    selectStatuses() {
      return ownersService.getOwnerStatusesMap().then(statusesList => {
        this.statusOptions = statusesList
      })
    },
    selectCallSources() {
      return ownersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    },
    onSubmit() {
      const isEditProfile = true
      this.loading = true
      ownersService
        .update(this.ownerData.id, this.localOwnerData, isEditProfile)
        .then(newData => {
          this.$emit('submitted', newData)
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.ownerProfile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
