<template>
  <div class="interaction-history-list-item-type-change">
    <div class="interaction-history-list-item-type-change__author">
      <v-avatar
        class="interaction-history-list-item-type-change__author-photo"
        size="small"
        :src="interactionAuthor.photo"
      ></v-avatar>
      {{ interactionAuthor.name }}
    </div>

    <div v-for="(change, index) in changes" :key="index">
      <template v-if="isPlainText(change)">
        <span>{{ change.prefix }}</span>
        <span v-if="isReplace(change)">{{ change.oldValue }} -> {{ change.newValue }}</span>
        <span v-else-if="isDelete(change)">{{ change.oldValue }}</span>
        <span v-else-if="isAdd(change)">{{ change.newValue }}</span>
      </template>
      <template v-else-if="hasName(change)">
        <span>{{ change.prefix }}</span>
        <span v-if="isReplace(change)">{{ change.oldValue.name }} -> {{ change.newValue.name }}</span>
        <span v-else-if="isDelete(change)">{{ change.oldValue.name }}</span>
        <span v-else-if="isAdd(change)">{{ change.newValue.name }}</span>
      </template>
      <template v-else-if="isAdverts(change)">
        <span>{{ change.prefix }}</span>
        <router-link
          v-if="isDelete(change)"
          :to="{ name: 'objects-realty-stat', params: { objectId: change.advertId } }"
          >{{ change.oldValue.name }}</router-link
        >
        <router-link
          v-else-if="isAdd(change)"
          :to="{ name: 'objects-realty-stat', params: { objectId: change.advertId } }"
          >{{ change.newValue.name }}</router-link
        >
      </template>
      <template v-else-if="isFiles(change)">
        <span>{{ change.prefix }}</span>
        <span>"{{ $options.DOCUMENTS_CATEGORIES_MAP[change.category] }}": </span>
        <span>{{ change.fileName }} </span>
      </template>
      <template v-else-if="historyDeleteType">
        <span>{{ change.prefix }}</span> '{{ change.postfix }}': <span>{{ change.text }} </span>
      </template>
      <template v-else>{{ change }}</template>
    </div>
  </div>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import { DOCUMENTS_CATEGORIES_MAP } from '@/constants/documentsCategories'
import { INTERACTION_HISTORY_DELETE_TYPES } from '@/constants/ownerInteractionHistory'

const EMAIL = 'email'
const PHONE = 'phone'
const NAME = 'name'
const STATUS = 'status'
const CALL_SOURCE = 'callSource'
const AGENT = 'agent'
const PREVIOUS_AGENT = 'previousAgent'
const ADVERTS = 'adverts'
const FILES_ADD = 'filesAdd'
const FILES_DELETE = 'filesDelete'
const HISTORY_DELETE = 'historyDelete'

const REPLACE = 'replace'
const DELETE = 'delete'
const ADD = 'add'

const CHANGE_TYPE = {
  [EMAIL]: {
    dataType: EMAIL,
    replacePrefix: 'Email: ',
    addPrefix: 'Добавлен email: ',
    deletePrefix: 'Удален email: '
  },
  [PHONE]: {
    dataType: PHONE,
    replacePrefix: 'Тел.: ',
    addPrefix: 'Добавлен тел.: ',
    deletePrefix: 'Удален тел.: '
  },
  [NAME]: {
    dataType: NAME,
    replacePrefix: 'Имя: ',
    addPrefix: 'Добавлено имя: ',
    deletePrefix: 'Удалено имя: '
  },
  [AGENT]: {
    dataType: AGENT,
    replacePrefix: 'Агент: ',
    addPrefix: 'Добавлен агент: ',
    deletePrefix: 'Удален агент: '
  },
  [STATUS]: {
    dataType: STATUS,
    replacePrefix: 'Статус: ',
    addPrefix: 'Добавлен статус: ',
    deletePrefix: 'Удален статус: '
  },
  [CALL_SOURCE]: {
    dataType: CALL_SOURCE,
    replacePrefix: 'Источник лида: ',
    addPrefix: 'Добавлен источник лида: ',
    deletePrefix: 'Удален источник лида: '
  },
  [PREVIOUS_AGENT]: {
    dataType: PREVIOUS_AGENT,
    replacePrefix: 'Агент первичный: ',
    addPrefix: 'Добавлен агент первичный: ',
    deletePrefix: 'Удален агент первичный: '
  },
  [ADVERTS]: {
    dataType: ADVERTS,
    addPrefix: 'Добавлен объект: ',
    deletePrefix: 'Удален объект: '
  },
  [FILES_ADD]: {
    dataType: FILES_ADD,
    addPrefix: 'Добавлен документ '
  },
  [FILES_DELETE]: {
    dataType: FILES_DELETE,
    deletePrefix: 'Удален документ '
  },
  [HISTORY_DELETE]: {
    dataType: HISTORY_DELETE,
    deletePrefix: 'Удалена запись '
  }
}

export default {
  name: 'VInteractionHistoryListItemTypeChange',
  components: { VAvatar },
  CHANGE_TYPE,
  DOCUMENTS_CATEGORIES_MAP,
  EMAIL,
  PHONE,
  NAME,
  STATUS,
  CALL_SOURCE,
  AGENT,
  PREVIOUS_AGENT,
  ADVERTS,

  REPLACE,
  DELETE,
  ADD,
  props: {
    interactionAuthor: {
      type: Object,
      default: () => null
    },
    interactionData: {
      type: Object,
      default: () => null
    },
    historyDeleteType: {
      type: [String],
      default: ''
    }
  },
  computed: {
    changes() {
      const changes = []
      const { email, phone, name, ownerProfile, customerProfile, filesAdd, filesDelete, text } = this.interactionData
      const entityData = { email, phone, name, filesAdd, filesDelete, ...ownerProfile, ...customerProfile }

      const changedEntityFields = ['email', 'phone', 'name', 'status', 'callSource', 'agent', 'previousAgent']

      changedEntityFields.forEach(fieldKey => {
        const changedField = entityData[fieldKey]
        if (changedField) {
          const changedData = this.getChange({ ...changedField, ...this.$options.CHANGE_TYPE[fieldKey] })
          changes.push(changedData)
        }
      })

      if (entityData.filesAdd) {
        changes.push(...this.getFilesChange({ files: entityData.filesAdd, ...CHANGE_TYPE.filesAdd }))
      }
      if (entityData.filesDelete) {
        changes.push(...this.getFilesChange({ files: entityData.filesDelete, ...CHANGE_TYPE.filesDelete }))
      }
      if (entityData.adverts) {
        changes.push(...this.getAdvertsChange({ ...entityData.adverts, ...this.$options.CHANGE_TYPE.adverts }))
      }
      if (this.historyDeleteType) {
        changes.push(...this.getHistoryDeleteChange({ text, ...this.$options.CHANGE_TYPE.historyDelete }))
      }
      if (!changes.length) changes.push('Без изменений')
      return changes
    }
  },
  methods: {
    getHistoryDeleteChange({ text, dataType, deletePrefix }) {
      const changes = []
      changes.push({
        dataType,
        text,
        prefix: deletePrefix,
        postfix: INTERACTION_HISTORY_DELETE_TYPES[this.historyDeleteType]
      })
      return changes
    },
    getChange({ oldValue, newValue, dataType, replacePrefix, addPrefix, deletePrefix }) {
      const data = { oldValue, newValue, dataType }
      if (oldValue && newValue) {
        data.changeType = this.$options.REPLACE
        data.prefix = replacePrefix
      }
      if (!oldValue && newValue) {
        data.changeType = this.$options.ADD
        data.prefix = addPrefix
      }
      if (oldValue && !newValue) {
        data.changeType = this.$options.DELETE
        data.prefix = deletePrefix
      }
      return data
    },
    getAdvertsChange({ oldValue, newValue, dataType, addPrefix, deletePrefix }) {
      const changes = []
      const oldAdverts = new Set(oldValue.map(advert => advert.id))
      newValue.forEach(advert => {
        if (!oldAdverts.has(advert.id)) {
          changes.push({
            dataType,
            advertId: advert.id,
            changeType: this.$options.ADD,
            prefix: addPrefix,
            newValue: advert
          })
        }
      })
      const newAdverts = new Set(newValue.map(advert => advert.id))
      oldValue.forEach(advert => {
        if (!newAdverts.has(advert.id)) {
          changes.push({
            dataType,
            advertId: advert.id,
            changeType: this.$options.DELETE,
            prefix: deletePrefix,
            oldValue: advert
          })
        }
      })
      return changes
    },
    getFilesChange({ files, dataType, addPrefix, deletePrefix }) {
      const changes = []
      files.forEach(file => {
        changes.push({
          dataType,
          fileName: file.fileName,
          changeType: dataType === FILES_ADD ? ADD : DELETE,
          prefix: dataType === FILES_ADD ? addPrefix : deletePrefix,
          category: file.category
        })
      })
      return changes
    },
    isPlainText({ dataType }) {
      return [this.$options.EMAIL, this.$options.PHONE, this.$options.NAME].includes(dataType)
    },
    hasName({ dataType }) {
      return [
        this.$options.STATUS,
        this.$options.CALL_SOURCE,
        this.$options.AGENT,
        this.$options.PREVIOUS_AGENT
      ].includes(dataType)
    },
    isAdverts({ dataType }) {
      return dataType === this.$options.ADVERTS
    },
    isFiles({ dataType }) {
      return dataType === FILES_ADD || dataType === FILES_DELETE
    },
    isReplace({ changeType }) {
      return changeType === this.$options.REPLACE
    },
    isDelete({ changeType }) {
      return changeType === this.$options.DELETE
    },
    isAdd({ changeType }) {
      return changeType === this.$options.ADD
    }
  }
}
</script>
