<template>
  <label class="v-button-file">
    <v-button :compressed="compressed" v-on="$listeners" @click="handleClick"><slot /></v-button>
    <input
      ref="fileInput"
      class="v-button-file__hidden"
      v-bind="$attrs"
      type="file"
      :accept="accept"
      @change="handleFileChange"
    />
  </label>
</template>

<script>
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'VButtonFile',
  components: { VButton },
  inheritAttrs: false,
  props: {
    accept: {
      type: String,
      default: '*'
    },
    compressed: { type: Boolean, default: false }
  },
  methods: {
    handleClick() {
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      this.$emit('change', event.target.files)
      this.$refs.fileInput.value = ''
    }
  }
}
</script>
