<template>
  <div class="v-timeline">
    <div v-if="title" class="v-timeline__title">{{ title }}</div>
    <div class="v-timeline__delimeter"></div>
    <div class="v-timeline__delimeter"></div>
    <div class="v-timeline__items"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'VTimeline',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
