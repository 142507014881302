<template>
  <v-dialog :visible.sync="localVisible" :title="title" class="edit-owner-dialog">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <template #default>
          <v-form-row>
            <v-form-field label="ТЕЛЕФОН" :rules="rules.phone" :error-message="errors.phone">
              <template #default="{ validationErrors }">
                <v-input-phone v-model="owner.phone" :is-error="!!validationErrors.length">
                  <template v-if="successAppend" #append>
                    <v-success v-if="successAppend === 'success'" class="edit-owner-dialog__success-icon"></v-success>
                    <v-not-success v-else></v-not-success>
                  </template>
                </v-input-phone>
              </template>
              <template #error="{ errorMessage }">
                <v-form-user-error :error-message="errorMessage" :user="phoneSearchUser" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ИМЯ СОБСТВЕННИКА" :rules="rules.name" :error-message="errors.name">
              <template #default="{ validationErrors }">
                <v-input v-model="owner.name" :is-error="!!validationErrors.length" type="text" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="EMAIL" :rules="rules.email" :error-message="errors.email">
              <template #default="{ validationErrors }">
                <v-input v-model="owner.email" :is-error="!!validationErrors.length" type="email" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ" :rules="rules.agent" :error-message="errors.agent">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="owner.agent"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="agentOptions"
                  :loading="agentOptionsLoading"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="СТАТУС" :rules="rules.status" :error-message="errors.status">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="owner.status"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="statusOptions"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА" :rules="rules.callSource" :error-message="errors.callSource">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="owner.callSource"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="callSourceOptions"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field
              label="ОБЪЕКТ"
              :rules="rules.object"
              separate-label
              hide-errors
              :error-message="errors.adverts"
            >
              <template #default="{ validationErrors }">
                <v-form-dynamic-select
                  v-model="owner.adverts"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :errors="validationErrors"
                  :options="advertOptions"
                  prevent-duplicates
                  :loading="advertOptionsLoading"
                  @search="onAdvertSearch"
                >
                </v-form-dynamic-select>
              </template>
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button class="edit-owner-dialog__cancel-button" type="button" @click="localVisible = false"
            >Отменить</v-button
          >
          <v-button :disabled="!isCreation && isFieldsNotChanged" primary type="submit">{{
            isCreation ? 'Добавить собственника' : 'Обновить контакт'
          }}</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VButton from '@/components/common/VButton.vue'
import VSelect from '@/components/common/VSelect.vue'
import VFormDynamicSelect from '@/components/form/VFormDynamicSelect.vue'
import { advertsService, agentsService, authService, ownersService } from '@/services/http'
import { formatAdvertName, formatAgentObject, getStringFromArrayByKeyValues } from '@/utils/formatters'
import { getFirstErrorForFields, cloneDeep, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VFormUserError from '@/components/form/VFormUserError.vue'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

export default {
  name: 'OwnerEditDialog',
  components: {
    VNotSuccess,
    VSuccess,
    VFormUserError,
    VDialog,
    VFormField,
    VFormRow,
    VButton,
    VInput,
    VForm,
    VSelect,
    VFormDynamicSelect,
    VInputPhone
  },
  props: {
    visible: { type: Boolean, required: true },
    ownerData: { type: Object, default: () => null }
  },
  data: () => {
    return {
      owner: {
        name: '',
        phone: '',
        email: '',
        agent: null,
        status: null,
        callSource: null,
        adverts: null
      },
      loading: false,
      errors: {},
      advertOptionsLoading: false,
      agentOptionsLoading: false,
      advertOptions: [],
      agentOptions: [],
      statusOptions: [],
      callSourceOptions: [],
      phoneSearchUser: null
    }
  },
  computed: {
    changedValues() {
      const changedKeys = []
      let changedAdvertsValue = 0
      const keys = Object.keys(this.owner)
      keys.forEach(key => {
        if (typeof this.owner[key] === 'object') {
          if (Array.isArray(this.owner[key])) {
            if (getStringFromArrayByKeyValues(this.owner[key]) !== getStringFromArrayByKeyValues(this.ownerData[key])) {
              changedKeys.push(key)
              changedAdvertsValue = this.owner[key].length - this.ownerData[key].length
            }
          } else if (this.owner[key]?.id !== this.ownerData[key]?.id) {
            changedKeys.push(key)
          }
        } else if (this.owner[key] !== this.ownerData[key]) {
          changedKeys.push(key)
        }
      })
      return { profileFields: changedKeys, adverts: changedAdvertsValue > 0 ? changedAdvertsValue : 0 }
    },
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string') {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.ownerData, this.owner, { isAdmin: this.isRoleAdmin })
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    rules() {
      return {
        object: ``,
        email: `${this.isCreation ? 'required|email' : ''}`,
        agent: `${this.isCreation ? 'required' : ''}`,
        phone: `${this.isCreation ? 'required' : ''}`,
        status: `${this.isCreation ? 'required' : ''}`,
        callSource: `${this.isCreation ? 'required' : ''}`,
        name: `${this.isCreation ? 'required' : ''}`
      }
    },

    isCreation() {
      return !(this.ownerData && this.ownerData.id)
    },
    title() {
      return this.isCreation ? 'Добавить собственника объекта недвижимости' : 'Редактировать собственника'
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    ownerPhone() {
      return this.owner.phone
    }
  },
  watch: {
    ownerPhone: {
      handler(val) {
        if (val.length === 11 && val !== this.ownerData.phone) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    ownerData: {
      immediate: true,
      handler() {
        this.resetLocalState()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalState()
      }
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectAdverts()
    this.selectStatuses()
    this.selectCallSources()
  },
  methods: {
    createOwner(ownerData) {
      this.loading = true
      return ownersService.create(ownerData).finally(() => {
        this.loading = false
      })
    },
    updateOwner({ id, ...ownerData }) {
      this.loading = true
      const isEditProfile = true
      return ownersService.update(id, ownerData, isEditProfile, this.changedValues).finally(() => {
        this.loading = false
      })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectAdverts(query) {
      this.advertOptionsLoading = true
      return advertsService
        .select(query)
        .then(advertsList => {
          this.advertOptions = advertsList.map(advert => {
            const { name, roundedPrice } = advert
            return {
              ...advert,
              name: `${name} - ${roundedPrice}`
            }
          })
        })
        .finally(() => {
          this.advertOptionsLoading = false
        })
    },
    selectStatuses() {
      return ownersService.getOwnerStatusesMap().then(statusesList => {
        this.statusOptions = statusesList
      })
    },
    selectCallSources() {
      return ownersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    },
    resetLocalState() {
      const emptyOwner = {
        name: '',
        phone: '',
        email: '',
        agent: null,
        callSource: null,
        status: null,
        adverts: []
      }
      this.owner = {
        ...emptyOwner,
        ...cloneDeep(this.ownerData),
        adverts: this.ownerData?.adverts?.length
          ? this.ownerData.adverts.map(advert => {
              return { ...advert, name: formatAdvertName(advert) }
            })
          : []
      }
      this.errors = {}
    },
    onSubmit() {
      const action = this.isCreation ? this.createOwner : this.updateOwner
      action(this.owner)
        .then(newData => {
          this.$emit('submitted', newData)
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.ownerProfile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
    },
    onAdvertSearch(query, loading) {
      loading(true)

      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    }
  }
}
</script>

<style lang="scss">
.edit-owner-dialog {
  &__cancel-button {
    margin-bottom: 8px;
  }

  &__success-icon {
    width: 16px;
  }
}
</style>
